import React, {
  useState,
  createContext,
  useContext,
  useReducer,
  useRef,
} from 'react';
import reducer from './reducer';

const initialState = {
  points: [],
  hotels: [],
  filteredPoints: [],
  filteredHotels: [],
  filteredEvents: [],
  filteredEat: [],
  filteredParking: [],
  filteredWlan: [],
  filteredSaved: [],
  filteredRanking: [],
  marathon: null,
  favoritesList: null,
  favoritesListlength: 0,
  filteredEatLength: 0,
  searchList: null,
  selectedPoint:null,
  selectedSpot:null,
  selectedUser:null,
  yourLocationCoordinates: null,
  yourLocationAddress: null,
  selectCategory: null,
  spotCoordinates: null,
  distanceFilter: 0,
  maxDistance: 1000,
}

const Context = createContext(initialState);

export const useValue = () => {
  return useContext(Context);
}

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [checked, setChecked] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [favorites, setFavorites]  = useState(false)
  const [resetCoords, setResetCoords] = useState(false)
  const [miles, setMiles] = useState(false)
  const [showPopup, setShowPopup] = useState(null)
  const [open, setOpen] = useState(false)
  const [openNew, setOpenNew] = useState(false)
  const [openRanking, setOpenRanking] = useState(false)
  const [openSaved, setOpenSaved] = useState(false)
  const [preselect, setPreselect] = useState(false)
  const [hoteldist, setHoteldist] = useState(0)
  const [selectedHotel, setSelectedHotel] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null);
  const [favmsg, setFavmsg] = useState(null)
  const [radialdist, setRadialdist] = useState(0)
  const [selectedCat, setSelectedCat] = useState("tokyo")
  const [topPage, setTopPage] = useState(0)
  const [hotelIndex, setHotelIndex] = useState(0)
  const [swiper, setSwiper] = useState()
  const [area, setArea] = useState(null)
  const [checkedValues, setCheckedValues] = useState([]);
  const [markerPosition, setMarkerPosition] = useState({longitude: 0, latitude:0});
  const [selectedPinPos, setSelectedPinPos] = useState(null);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [selectedPointLayer, setSelectedPointLayer] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [actPopupInfo, setActPopupInfo] = useState(null);
  const [coords, setCoords] = useState([])
  const [spinner, setSpinner] = useState(false);
  const [snapPoint, setSnapPoint] = useState(100);
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('all'); 
  const mapRef = useRef()
  const containerRef = useRef()
  const sheetRef = useRef();

  const values = {
        state,
        dispatch,
        mapRef,
        sheetRef,
        containerRef,
        checked,
        setChecked,
        isOpen,
        setIsOpen,
        swiper,
        setSwiper,
        favorites,
        setFavorites,
        resetCoords,
        setResetCoords,
        miles,
        setMiles,
        showPopup,
        setShowPopup,
        open,
        setOpen,
        openSaved,
        setOpenSaved,
        hoteldist,
        setHoteldist,
        selectedHotel,
        setSelectedHotel,
        favmsg,
        setFavmsg,
        anchorEl,
        setAnchorEl,
        selectedCat,
        setSelectedCat,
        hotelIndex,
        setHotelIndex,
        area,
        setArea,
        checkedValues,
        setCheckedValues,
        markerPosition,
        setMarkerPosition,
        openNew,
        setOpenNew,
        selectedPinPos,
        setSelectedPinPos,
        selectedLayer,
        setSelectedLayer,
        selectedPointLayer,
        setSelectedPointLayer,
        preselect,
        setPreselect,
        coords,
        setCoords,
        popupInfo,
        setPopupInfo,
        actPopupInfo,
        setActPopupInfo,
        spinner,
        setSpinner,
        snapPoint,
        setSnapPoint,
        isPlayerVisible,
        setIsPlayerVisible,
        selectedMenu,
        setSelectedMenu,
        openRanking,
        setOpenRanking
  }

  return (
    <Context.Provider value={values}>
     {children}
    </Context.Provider>
  )
}

export default ContextProvider;